/*
 * Copyright (C) 2024 AIHR
 * License EULA
 *
 * This software and its contents are the property of [AIHR].
 * Unauthorized copying of this file, via any medium, is strictly prohibited.
 * Proprietary and confidential.
 */

// GENERAL Constants
export const TitlePrefix = 'AIHR | ';
export const LearningCatalogTitle = 'AIHR | Courses';
export const onboardingLocalStorageKey = 'onboarding';