/*
 * Copyright (C) 2024 AIHR
 * License EULA
 *
 * This software and its contents are the property of [AIHR].
 * Unauthorized copying of this file, via any medium, is strictly prohibited.
 * Proprietary and confidential.
 */

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, Subject } from 'rxjs';
import { map, catchError, switchMap, mergeMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { downloadFile, downloadFileFailure, downloadFileSuccess, thumbsDown, thumbsDownSuccess, thumbsUp, thumbsUpSuccess, completeLesson, completeLessonFailure, completeLessonSuccess, startDownloadFile, cancelDownloadFile, downloadFileCanceled } from '@learning-content/store';
import { LessonService } from '@services/shared';
import { stopLoading } from '@store/loading';
import { RatingService } from '@learning-content/services';
import { clearCompletedDownloads, clearDownloadItems, downloadItems, finishedDownloadWithError, finishedDownloadWithSuccess, startDownloadingItem } from '@store/downloading';
import { Store } from '@ngrx/store';
import MimeTypes from '../../assets/common-MIME-types.json';

@Injectable()
export class LearningContentEffects {
    cancelSubjectsMap = new Map<string, Subject<void>>();

    constructor(
        private readonly _store: Store,
        private readonly _actions$: Actions,
        private readonly _lessonService: LessonService,
        private readonly _ratingService: RatingService
    ) { }

    downloadFile$ = createEffect(() =>
        this._actions$.pipe(
            ofType(downloadFile),
            map(({ rootJourneyId, fileId, fileName }) => {
                const subject = new Subject<void>();
                this.cancelSubjectsMap.set(fileId, subject);
                return startDownloadFile({
                    fileId,
                    fileName,
                    downloadFileObservable: this._lessonService.downloadFile(rootJourneyId, fileId).pipe(takeUntil(subject)),
                })
            }
            )));

    startDownloadFile$ = createEffect(() =>
        this._actions$.pipe(
            ofType(startDownloadFile),
            mergeMap(({ fileId, fileName, downloadFileObservable }) => downloadFileObservable
                .pipe(map(data => {
                    const blob = this.createBlob(data.body, fileName);
                    const downloadURL = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = downloadURL;
                    link.download = fileName;
                    link.click();

                    return downloadFileSuccess({ fileId });
                }),
                    catchError(_ => of(downloadFileFailure({ fileId })))
                ))
        ));


    startDownloadFileLoading$ = createEffect(() =>
        this._actions$.pipe(
            ofType(startDownloadFile),
            map(({ fileId, fileName, downloadFileObservable }) => startDownloadingItem({ id: fileId, title: fileName, downloadFileObservable }))
        ));

    cancelDownloadFile$ = createEffect(() =>
        this._actions$.pipe(
            ofType(cancelDownloadFile),
            map(({ fileId }) => {
                this.cancelItem(fileId);

                return downloadFileCanceled({ fileId });
            })
        ));

    finishedDownloadFileLoadingWithSuccess$ = createEffect(() =>
        this._actions$.pipe(
            ofType(downloadFileSuccess),
            map(({ fileId }) => finishedDownloadWithSuccess({ id: fileId }))
        ));

    finishedDownloadFileLoadingWithError$ = createEffect(() =>
        this._actions$.pipe(
            ofType(downloadFileFailure),
            map(({ fileId }) => finishedDownloadWithError({ id: fileId }))
        ));

    unsubscribeCompletedItems$ = createEffect(() =>
        this._actions$.pipe(
            ofType(clearCompletedDownloads),
            withLatestFrom(this._store.select(downloadItems)),
            map(([_, items]) => {
                const completedItems = Array.from(items.values()).filter(item => item.isSuccess);
                completedItems.forEach(item => this.cancelItem(item.id));
            })), { dispatch: false });


    unsubscribeAllItems$ = createEffect(() =>
        this._actions$.pipe(
            ofType(clearDownloadItems),
            withLatestFrom(this._store.select(downloadItems)),
            map(([_, items]) => {
                items.forEach((__, key) => this.cancelItem(key));
            })), { dispatch: false });

    thumbsUp$ = createEffect(() =>
        this._actions$.pipe(
            ofType(thumbsUp),
            switchMap(({ rootJourneyId, lessonId, comment }) =>
                this._ratingService.thumbsUp(rootJourneyId, lessonId, comment).pipe(
                    map(() => thumbsUpSuccess()),
                )
            )
        ));

    thumbsDown$ = createEffect(() =>
        this._actions$.pipe(
            ofType(thumbsDown),
            switchMap(({ rootJourneyId, lessonId, comment }) =>
                this._ratingService.thumbsDown(rootJourneyId, lessonId, comment).pipe(
                    map(() => thumbsDownSuccess()),
                )
            )
        ));

    completeLesson$ = createEffect(() =>
        this._actions$.pipe(
            ofType(completeLesson),
            switchMap(({ rootJourneyId, lessonParentJourneyId, lessonId }) =>
                this._lessonService.completeLesson(rootJourneyId, lessonParentJourneyId, lessonId).pipe(
                    map(() => completeLessonSuccess()),
                    catchError(() => of(completeLessonFailure()))
                )
            )
        ));

    completeLessonFailure$ = createEffect(() =>
        this._actions$.pipe(
            ofType(completeLessonFailure),
            map(() => stopLoading())
        )
    );

    private cancelItem(id: string): void {
        const item = this.cancelSubjectsMap.get(id);
        if (item) {
            item.next();
            item.complete();
            this.cancelSubjectsMap.delete(id);
        }
    }

    private createBlob(data: Blob, fileName: string): Blob {
        const fileExtension = fileName.split('.').pop();
        const type = MimeTypes[fileExtension];

        return type ? new Blob([data], { type }) : data;
    }
}
