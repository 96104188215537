<div *ngIf="isModalActive">
    <div class="background" (click)="outsideClick()" tabindex="-1"></div>
    <div class="card-container">
        <div class="card" [class.with-buttons]="primaryButtonInput">
            <button class="close-button" (click)="closeButtonClick()" [disabled]="isLoading">
                <i class="fal fa-xmark" aria-hidden="true" ></i>
            </button>
            <div class="card-content-container">
                <img class="card-image" [src]="imagePath" alt="" />
                <div class="title h1-alternative">{{ title | translate }}</div>
                <div class="n16-regular description">{{ description | translate }}</div>
                <ng-container *ngTemplateOutlet="additionalContentTemplateRef"></ng-container>
                <div class="buttons-area" *ngIf="primaryButtonInput">
                    <div class="button-container">
                        <button
                            class="primary-button cta-button n14-bold"
                            *ngIf="primaryButtonInput"
                            [disabled]="primaryButtonInput.disabled"
                            (click)="primaryButtonClick()">
                            {{ primaryButtonInput.label | translate }}
                        </button>
                    </div>
                    <div class="button-container">
                        <button
                            class="secondary-button cta-button n14-bold"
                            *ngIf="secondaryButtonInput"
                            [disabled]="secondaryButtonInput.disabled"
                            (click)="secondaryButtonClick()">
                            {{ secondaryButtonInput.label | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
