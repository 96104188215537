import config from '../app.settings.json';
import {IDefaultConfiguration} from '@interfaces';

const {
    domain,
    clientId,
    clientIdRegularApplication,
    audience,
    usersApiUri,
    usersApiVersion,
    catalogApiVersion,
    appUri,
    catalogApiUri,
    wss,
    errorPath,
    geolocationApiKey,
    envName,
    translationsApiUri,
    translationsApiVersion,
    accountManagementAppUrl,
    checkoutAppUri,
    lmsApiUri,
    cloudFrontUrl,
    resourcesUrl,
    launchDarklyClientId,
    launchDarklyUserId,
    helpCenterUrl,
    betterModeUrl
} = config as {
    domain: string;
    clientId: string;
    clientIdRegularApplication: string;
    audience: string;
    usersApiUri: string;
    usersApiVersion: string;
    catalogApiUri: string;
    catalogApiVersion: string;
    appUri: string;
    wss: string;
    errorPath: string;
    geolocationApiKey: string;
    envName: string;
    translationsApiUri: string;
    translationsApiVersion: string;
    accountManagementAppUrl: string;
    checkoutAppUri: string;
    lmsApiUri: string;
    cloudFrontUrl: string;
    resourcesUrl: string;
    launchDarklyClientId: string;
    launchDarklyUserId: string;
    helpCenterUrl: string;
    betterModeUrl: string;
};

const defaultConfiguration: IDefaultConfiguration = {
    production: false,
    applicationVersion: "1.0.0",
    auth: {
        domain,
        clientId,
        clientIdRegularApplication,
        audience,
        redirectUri: window.location.origin,
        usersApiUri,
        usersApiVersion,
        catalogApiUri,
        catalogApiVersion,
        appUri,
        wss,
        errorPath,
        geolocationApiKey,
        envName,
        translationsApiUri,
        translationsApiVersion,
        accountManagementAppUrl,
        checkoutAppUri,
        lmsApiUri,
        resourcesUrl,
        launchDarklyClientId,
        launchDarklyUserId,
        helpCenterUrl,
        betterModeUrl
    },
    cloudFrontUrl
}

export default {defaultConfiguration};
