/*
 * Copyright (C) 2024 AIHR
 * License EULA
 *
 * This software and its contents are the property of [AIHR].
 * Unauthorized copying of this file, via any medium, is strictly prohibited.
 * Proprietary and confidential.
 */

export const ENVIRONMENTS = {
    DEVELOPMENT: 'dev',
    STAGING: 'staging',
    QA: 'qa',
    CICD: 'cicd',
    PRODUCTION: 'production',
    NEXT: 'next',
    LOCAL: 'local',
    E2E: 'e2e'
}