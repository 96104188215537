/*
 * Copyright (C) 2024 AIHR
 * License EULA
 *
 * This software and its contents are the property of [AIHR].
 * Unauthorized copying of this file, via any medium, is strictly prohibited.
 * Proprietary and confidential.
 */

import { Injectable } from '@angular/core';
import {
    MatSnackBar,
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { NotificationCardComponent } from '@components/notification-card/notification-card.component';
import { NotificationType } from '@enums';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    constructor(private readonly _snackBar: MatSnackBar) {}

    public error(message?: string): void {
        this.openNotification(NotificationType.Error, message);
    }

    public warning(message: string): void {
        this.openNotification(NotificationType.Warning, message);
    }

    public information(title: string, message: string, durationInMilliseconds = 5000): void {
        this.openNotification(NotificationType.Information, message, title, durationInMilliseconds);
    }

    private openNotification(
        type: NotificationType,
        message = 'An unknown error occurred. Please try again in a few minutes. If the error persists, please contact support.',
        title = 'Something went wrong',
        durationInMilliseconds = 5000,
        horizontal: MatSnackBarHorizontalPosition = 'center',
        vertical: MatSnackBarVerticalPosition = 'top'
    ): void {
        this._snackBar.openFromComponent(NotificationCardComponent, {
            data: {
                type,
                title,
                description: message,
            },
            horizontalPosition: horizontal,
            verticalPosition: vertical,
            duration: durationInMilliseconds,
            panelClass: ['snack-bar'],
        });
    }
}
