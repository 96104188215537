/*
 * Copyright (C) 2024 AIHR
 * License EULA
 *
 * This software and its contents are the property of [AIHR].
 * Unauthorized copying of this file, via any medium, is strictly prohibited.
 * Proprietary and confidential.
 */

export { LearningCatalogTitle, TitlePrefix, onboardingLocalStorageKey } from './constants';
export { learningCatalogStoreKey, downloadingStoreKey, learningContentStoreKey, loadingStoreKey, rolesStoreKey } from './store-constants';
export { ENVIRONMENTS } from './environments-constants';
export { CopilotNamingKey, StandaloneResourcesLibraryKey } from './feature-flag-constants';
export { SvgPath } from './path-constants';
export { MaxWidthMediumScreen, getScreenSize } from './screen-size-constants';
