/*
 * Copyright (C) 2024 AIHR
 * License EULA
 *
 * This software and its contents are the property of [AIHR].
 * Unauthorized copying of this file, via any medium, is strictly prohibited.
 * Proprietary and confidential.
 */

export {startLoading, stopLoading} from './loading.actions';
export {loadingReducer, ILoadingState} from './loading.reducer';
export {isLoading} from './loading.selector';