/*
 * Copyright (C) 2024 AIHR
 * License EULA
 * 
 * This software and its contents are the property of [AIHR].
 * Unauthorized copying of this file, via any medium, is strictly prohibited.
 * Proprietary and confidential.
 */

import {inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn} from '@angular/router';
import {first, Observable, Subscriber} from 'rxjs';
import environment from '@environment';
import { AuthenticationService, LocalStorageService } from '@services/core';

@Injectable({
    providedIn: 'root'
})
export class RolesService {
    constructor(
        private readonly _authenticationService: AuthenticationService,
        private readonly _localStorageService: LocalStorageService,
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        return new Observable<boolean>(observer => {
            this._authenticationService.isUserAuthenticated().pipe(first()).subscribe({
                next: isAuthenticated => {
                    if (!isAuthenticated) {
                        this._authenticationService.logout();
                        observer.next(false);
                        observer.complete();
                        return;
                    }

                    this.checkUserAccessAndRoles(observer, route);
                },
                error: () => {
                    this._authenticationService.logout();
                    observer.next(false);
                    observer.complete();
                }
            });
        });
    }

    private redirectToAccountManagement(): void {
        this._localStorageService.clear();
        window.location.href = `${environment.defaultConfiguration.auth.accountManagementAppUrl}/main/account-management/account-details`;
    }

    private checkUserAccessAndRoles(observer: Subscriber<boolean>, route: ActivatedRouteSnapshot): void {
        this._authenticationService.userHasRequiredRole(route.data.roles).pipe(first()).subscribe(hasRequiredRole => {
            if (!hasRequiredRole) {
                this.redirectToAccountManagement();

                observer.next(false);
                observer.complete();
                return;
            }

            observer.next(true);
            observer.complete();
        });
    }
}


export const rolesGuard: CanActivateFn = (route: ActivatedRouteSnapshot): Observable<boolean> => inject(RolesService).canActivate(route);
