/*
 * Copyright (C) 2024 AIHR
 * License EULA
 *
 * This software and its contents are the property of [AIHR].
 * Unauthorized copying of this file, via any medium, is strictly prohibited.
 * Proprietary and confidential.
 */

import { createReducer, on } from '@ngrx/store';
import {startLoading, stopLoading} from './loading.actions';

export interface ILoadingState {
    isLoading: boolean;
}

export const initialState: ILoadingState = {
    isLoading: false
}

export const loadingReducer = createReducer(
    initialState,
    on(startLoading, state => ({...state, isLoading: true})),
    on(stopLoading, state => ({...state, isLoading: false})),
);
