/*
 * Copyright (C) 2024 AIHR
 * License EULA
 *
 * This software and its contents are the property of [AIHR].
 * Unauthorized copying of this file, via any medium, is strictly prohibited.
 * Proprietary and confidential.
 */

import { createAction, props } from '@ngrx/store';
import { IModalInput } from '@interfaces';

export const showModal = createAction('[Overlay Modal] Show Modal', props<{ modalInput: IModalInput }>());

