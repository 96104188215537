/*
 * Copyright (C) 2024 AIHR
 * License EULA
 *
 * This software and its contents are the property of [AIHR].
 * Unauthorized copying of this file, via any medium, is strictly prohibited.
 * Proprietary and confidential.
 */

import { createReducer, on } from '@ngrx/store';
import { cancelDownloadingItem, clearCompletedDownloads, clearDownloadItems, finishedDownloadWithError, finishedDownloadWithSuccess, retryDownloadingItem, startDownloadingItem } from './downloading.actions';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';

export interface IDownloadItem {
    id: string;
    title: string;
    downloadFileObservable: Observable<HttpResponse<Blob>>;
    isSuccess?: boolean;
    isCanceled?: boolean;
}

export interface IDownloadingState {
    downloadItems: Map<string, IDownloadItem>;
}

export const initialState: IDownloadingState = {
    downloadItems: new Map<string, IDownloadItem>()
}

export const downloadingReducer = createReducer(
    initialState,
    on(startDownloadingItem, (state, action) => {
        const map = new Map(state.downloadItems);
        map.set(action.id, { id: action.id, title: action.title, downloadFileObservable: action.downloadFileObservable });
        return ({ ...state, downloadItems: map })
    }),
    on(finishedDownloadWithSuccess, (state, action) => updateStateMap(state, action.id, item => item.isSuccess = true)),
    on(finishedDownloadWithError, (state, action) => updateStateMap(state, action.id, item => item.isSuccess = false)),
    on(cancelDownloadingItem, (state, action) => updateStateMap(state, action.id, item => { item.isCanceled = true; item.isSuccess = false; })),
    on(retryDownloadingItem, (state, action) => updateStateMap(state, action.id, item => item.isSuccess = undefined)),
    on(clearCompletedDownloads, state => {
        const map = new Map(state.downloadItems);
        map.forEach((value, key) => {
            if (value.isSuccess) {
                map.delete(key);
            }
        });
        return ({ ...state, downloadItems: map })
    }),
    on(clearDownloadItems, state => ({ ...state, downloadItems: new Map<string, IDownloadItem>() }))
);

const updateStateMap = (state: IDownloadingState, id: string, updateItemFunction: (item: IDownloadItem) => void): IDownloadingState => {
    const map = new Map(state.downloadItems);
    const item = map.get(id);
    if (item) {
        updateItemFunction(item);
        map.set(id, {...item});
    }
    return ({ ...state, downloadItems: map })
}
