/*
 * Copyright (C) 2024 AIHR
 * License EULA
 *
 * This software and its contents are the property of [AIHR].
 * Unauthorized copying of this file, via any medium, is strictly prohibited.
 * Proprietary and confidential.
 */

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { cancelDownloadingItem, retryDownloadingItem } from './downloading.actions';
import { Store } from '@ngrx/store';
import { downloadItems } from './downloading.selector';
import { map, withLatestFrom } from 'rxjs';
import { cancelDownloadFile, startDownloadFile } from '@learning-content/store';


@Injectable()
export class DownloadingEffects {
    constructor(
        private readonly _actions$: Actions,
        private readonly _store: Store,
    ) { }

    cancelDownloading$ = createEffect(() =>
        this._actions$.pipe(
            ofType(cancelDownloadingItem),
            map(({ id }) => cancelDownloadFile({ fileId: id })
        )));

    retryDownloading$ = createEffect(() =>
        this._actions$.pipe(
            ofType(retryDownloadingItem),
            withLatestFrom(this._store.select(downloadItems)),
            map(([{ id }, items]) => {
                const item = items.get(id);
                return startDownloadFile({ fileId: id, fileName: item.title, downloadFileObservable: item.downloadFileObservable });
            }
        )));
}
