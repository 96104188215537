/*
 * Copyright (C) 2024 AIHR
 * License EULA
 *
 * This software and its contents are the property of [AIHR].
 * Unauthorized copying of this file, via any medium, is strictly prohibited.
 * Proprietary and confidential.
 */

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import environment from '@environment';
import {ApiService} from '@services/core';
import {IAssessmentInitOptions} from '../interfaces/assessment.interface';

@Injectable()
export class AssessmentService {
    private readonly lmsAPIUri: string;

    constructor(private readonly _apiService: ApiService) {
        this.lmsAPIUri = environment.defaultConfiguration.auth.lmsApiUri;
    }

    public getActivityConfig(activityId: string): Observable<IAssessmentInitOptions> {
        const endpoint = `${this.lmsAPIUri}/api/v1/assess/${activityId}`;
        return this._apiService.get(endpoint);
    }
}
