/*
 * Copyright (C) 2024 AIHR
 * License EULA
 *
 * This software and its contents are the property of [AIHR].
 * Unauthorized copying of this file, via any medium, is strictly prohibited.
 * Proprietary and confidential.
 */

import {Injectable} from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpStatusCode
} from '@angular/common/http';
import {catchError, Observable, retry, throwError, timer} from 'rxjs';
import {Router} from '@angular/router';
import {tap} from 'rxjs/operators';


@Injectable()
export class ErrorsInterceptor implements HttpInterceptor {
    private readonly retryDelays = [
        500,   // firstAttempt
        1000,  // secondAttempt
        1500,  // thirdAttempt
        5000,  // fourthAttempt
        10000, // fifthAttempt
        30000  // sixthAttempt
    ];
    private readonly maxRetries = this.retryDelays.length;

    constructor(private readonly _router: Router) {
    }

    intercept<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
        const shouldSkipErrorHandling = request.headers?.get('skipErrorHandling') !== null;
        if (shouldSkipErrorHandling) {
            return next.handle(request);
        }

        return next.handle(request).pipe(
            tap((event: HttpEvent<T>) => {
                if (event instanceof HttpResponse && event.status === HttpStatusCode.NoContent) {
                    this._router.navigate(['/error/not-found']);
                }
            }),
            retry({
                count: this.maxRetries,
                delay: (error, retryCount) => {

                    if (this.shouldSkipRetry(error.status)) {
                        return throwError(() => error);
                    }

                    return timer(this.retryDelays[retryCount - 1]);
                }
            }),
            catchError((err: HttpErrorResponse) => {
                this.handleError(err);
                return throwError(() => new HttpErrorResponse(err));
            })
        );
    }

    private shouldSkipRetry(statusCode: HttpStatusCode): boolean {
        return [
            HttpStatusCode.NoContent,
            HttpStatusCode.BadRequest,
            HttpStatusCode.Forbidden,
            HttpStatusCode.NotFound,
            HttpStatusCode.Unauthorized
        ].includes(statusCode);
    }

    private handleError(error: HttpErrorResponse): void {
        console.error('Error occurred:', error);

        switch (error.status) {
            case HttpStatusCode.Forbidden:
                this._router.navigate(['/error/not-authorized']);
                break;

            case HttpStatusCode.NotFound:
                this._router.navigate(['/error/not-found']);
                break;

            case HttpStatusCode.InternalServerError:
            case HttpStatusCode.ServiceUnavailable:
            default:
                this._router.navigate(['/error/server-down']);
                break;
        }
    }
}
