import {BrowserModule} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app.routes';
import {AuthModule} from '@auth0/auth0-angular';
import environment from '@environment';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {ActionReducer, MetaReducer, StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {EffectsModule} from '@ngrx/effects';
import {localStorageSync} from 'ngrx-store-localstorage';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { OverlayModalComponent } from '@components/overlay-modal/overlay-modal.component';
import { learningCatalogStoreKey, rolesStoreKey, loadingStoreKey, downloadingStoreKey } from '@constants';
import { TranslationLoaderService } from '@services/core';
import { loadingReducer } from '@store/loading';
import { rolesReducer } from '@store/roles';
import { GlobalErrorHandler } from '@error-handler';
import { AuthorizationInterceptor } from '@interceptors/authorization-interceptor';
import { CacheControlInterceptor } from '@interceptors/cache-control-interceptor';
import { ErrorsInterceptor } from '@interceptors/errors-interceptor';
import { DownloadingEffects, downloadingReducer } from '@store/downloading';

export function sessionStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return localStorageSync({keys: [learningCatalogStoreKey], storage: sessionStorage})(reducer);
}

const metaReducers: Array<MetaReducer<any, any>> = [sessionStorageSyncReducer];

const {defaultConfiguration} = environment;

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        AuthModule.forRoot({
            cacheLocation: 'localstorage',
            domain: defaultConfiguration.auth.domain,
            clientId: defaultConfiguration.auth.clientId,
            authorizationParams: {
                responseType: 'token',
                scope: 'openId profile email offline_access',
                audience: defaultConfiguration.auth.audience,
                redirect_uri: defaultConfiguration.auth.appUri,
            }
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: TranslationLoaderService,
                deps: [HttpClient],
            },
        }),
        OverlayModalComponent,
        StoreModule.forRoot({
            [rolesStoreKey]: rolesReducer,
            [loadingStoreKey]: loadingReducer,
            [downloadingStoreKey]: downloadingReducer,
        },
        { metaReducers }),
        EffectsModule.forRoot(DownloadingEffects),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.defaultConfiguration.production
        })
    ],
    declarations: [
        AppComponent
    ],
    providers: [
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorsInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthorizationInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CacheControlInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
}