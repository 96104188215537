/*
 * Copyright (C) 2024 AIHR
 * License EULA
 *
 * This software and its contents are the property of [AIHR].
 * Unauthorized copying of this file, via any medium, is strictly prohibited.
 * Proprietary and confidential.
 */

export { setRoles } from './roles.actions';
export { IRolesState, rolesReducer } from './roles.reducer';
export { getRoles } from './roles.selector';