/*
 * Copyright (C) 2024 AIHR
 * License EULA
 *
 * This software and its contents are the property of [AIHR].
 * Unauthorized copying of this file, via any medium, is strictly prohibited.
 * Proprietary and confidential.
 */

import { createAction } from '@ngrx/store';

export const startLoading = createAction( '[Loading] Start Loading');
export const stopLoading = createAction('[Loading] Stop Loading ');

