import { createAction, props } from "@ngrx/store";
import { LearningStatus } from "@enums";
import { Observable } from "rxjs";
import { HttpResponse } from "@angular/common/http";

export const setNextLessonId = createAction('[Learning-Content] Set next lesson id', props<{ nextLessonId: number }>());
export const setPreviousLessonId = createAction('[Learning-Content] Set previous lesson id', props<{ previousLessonId: number }>());
export const setRootJourneyLastLessonId = createAction('[Learning-Content] Set root journey last lesson id', props<{ rootJourneyLastLessonId: number }>());
export const setJourneyTitle = createAction('[Learning-Content] Set journey title', props<{ journeyTitle: string }>());
export const setCurrentLessonParentJourneyId = createAction('[Learning-Content] Set current lessons parent Journey Id', props<{ currentLessonParentJourneyId: number }>());
export const setJourneyLearningStatus = createAction('[Learning-Content] Set journey learning status', props<{ learningStatus: LearningStatus }>());
export const setIsCurrentLessonLocked = createAction('[Learning-Content] Set is current lesson locked', props<{ isCurrentLessonLocked: boolean }>());
export const setCurrentInProgressLessonUrl = createAction('[Learning-Content] Set current in progress lesson url', props<{ currentInProgressLessonUrl: string }>());
export const downloadFile = createAction('[Learning-Content] Download file', props<{ rootJourneyId: number, fileId: string, fileName: string, alternativeName: string }>());
export const startDownloadFile = createAction('[Learning-Content] Start download file', props<{ fileId: string, fileName: string, downloadFileObservable: Observable<HttpResponse<Blob>> }>());
export const retryDownloadFile = createAction('[Learning-Content] Retry download file', props<{ fileId: string, fileName: string, downloadFileObservable: Observable<HttpResponse<Blob>> }>());
export const cancelDownloadFile = createAction('[Learning-Content] Cancel download file', props<{ fileId: string }>());
export const downloadFileSuccess = createAction('[Learning-Content] Download file success', props<{ fileId: string }>());
export const downloadFileFailure = createAction('[Learning-Content] Download file failure', props<{ fileId: string }>());
export const downloadFileCanceled = createAction('[Learning-Content] Download file canceled', props<{ fileId: string }>());
export const thumbsUp = createAction('[Learning-Content] Thumbs up', props<{ rootJourneyId: number , lessonId: number, comment: string}>());
export const thumbsDown = createAction('[Learning-Content] Thumbs down', props<{ rootJourneyId: number , lessonId: number, comment: string}>());
export const thumbsUpSuccess = createAction('[Learning-Content] Thumbs up success');
export const thumbsDownSuccess = createAction('[Learning-Content] Thumbs down success');
export const completeLesson = createAction('[Learning-Content] Complete lesson', props<{ rootJourneyId: number, lessonParentJourneyId: number, lessonId: number }>());
export const completeLessonSuccess = createAction('[Learning-Content] Complete lesson success');
export const completeLessonFailure = createAction('[Learning-Content] Complete lesson failure');
