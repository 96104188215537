/*
 * Copyright (C) 2024 AIHR
 * License EULA
 *
 * This software and its contents are the property of [AIHR].
 * Unauthorized copying of this file, via any medium, is strictly prohibited.
 * Proprietary and confidential.
 */

import {Injectable} from '@angular/core';
import {ApiService} from '@services/core';
import {Observable} from 'rxjs';
import environment from '@environment';
import {IJourney} from '@interfaces';

@Injectable()
export class JourneyService {
    private readonly _lmsAPIUri: string;

    constructor(private readonly _apiService: ApiService) {
        this._lmsAPIUri = environment.defaultConfiguration.auth.lmsApiUri;
    }

    public getJourneyById(id: number): Observable<IJourney> {
        const endpoint = `${this._lmsAPIUri}/api/v1/journey/${id}`;
        return this._apiService.get(endpoint);
    }

    public adminGetJourneyById(id: number): Observable<IJourney> {
        const endpoint = `${this._lmsAPIUri}/admin/api/v1/journey/${id}`;
        return this._apiService.get(endpoint);
    }

    public getJourneyOverviewById(id: number): Observable<IJourney> {
        const endpoint = `${this._lmsAPIUri}/api/v1/journey/overview/${id}`;
        return this._apiService.get(endpoint);
    }

    public completeRootParentJourney(rootParentJourneyId: number): Observable<void> {
        const endpoint = `${this._lmsAPIUri}/api/v1/journey/${rootParentJourneyId}/complete`;
        return this._apiService.put(endpoint);
    }
}
