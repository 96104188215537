/*
 * Copyright (C) 2024 AIHR
 * License EULA
 *
 * This software and its contents are the property of [AIHR].
 * Unauthorized copying of this file, via any medium, is strictly prohibited.
 * Proprietary and confidential.
 */

import {createFeatureSelector, createSelector} from '@ngrx/store';
import {IRolesState} from './roles.reducer';

export const rolesState = createFeatureSelector<IRolesState>('roles');
export const getRoles = createSelector(rolesState, state => state.roles)