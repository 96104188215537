/*
 * Copyright (C) 2024 AIHR
 * License EULA
 *
 * This software and its contents are the property of [AIHR].
 * Unauthorized copying of this file, via any medium, is strictly prohibited.
 * Proprietary and confidential.
 */

import { HttpResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { Observable } from 'rxjs';

export const startDownloadingItem = createAction('[Loading] Start Downloading Item', props<{ id: string, title: string, downloadFileObservable: Observable<HttpResponse<Blob>> }>());
export const cancelDownloadingItem = createAction('[Loading] Cancel Downloading Item', props<{ id: string }>());
export const retryDownloadingItem = createAction('[Loading] Retry Downloading Item', props<{ id: string }>());
export const clearDownloadItems = createAction('[Loading] Clear Download Items');
export const clearCompletedDownloads = createAction('[Loading] Clear Completed Downloads');
export const finishedDownloadWithSuccess = createAction('[Loading] Finished Downloading Item With Success', props<{ id: string }>());
export const finishedDownloadWithError = createAction('[Loading] Finished Downloading Item With Error', props<{ id: string }>());
export const finishedDownloadingItems = createAction('[Loading] Finished Downloading Items');
