/*
 * Copyright (C) 2024 AIHR
 * License EULA
 *
 * This software and its contents are the property of [AIHR].
 * Unauthorized copying of this file, via any medium, is strictly prohibited.
 * Proprietary and confidential.
 */

import {createFeatureSelector, createSelector} from '@ngrx/store';
import {ILoadingState} from './loading.reducer';

export const loadingState = createFeatureSelector<ILoadingState>('loading');
export const isLoading = createSelector(loadingState, state => state.isLoading)