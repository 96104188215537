/*
 * Copyright (C) 2024 AIHR
 * License EULA
 *
 * This software and its contents are the property of [AIHR].
 * Unauthorized copying of this file, via any medium, is strictly prohibited.
 * Proprietary and confidential.
 */

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import environment from '@environment';
import {ApiService} from '@services/core';

@Injectable({
    providedIn: 'root'
})
export class RatingService {
    private readonly lmsAPIUri: string;

    constructor(private readonly _apiService: ApiService) {
        this.lmsAPIUri = environment.defaultConfiguration.auth.lmsApiUri;
    }

    public thumbsUp(rootJourneyId: number, lessonId: number, comment: string): Observable<void> {
        const payload = {
            comment
        }
        const endpoint = `${this.lmsAPIUri}/api/v1/journey/${rootJourneyId}/${lessonId}/thumbs-up`;
        return this._apiService.post(endpoint, payload);
    }

    public thumbsDown(rootJourneyId: number, lessonId: number, comment: string): Observable<void> {
        const payload = {
            comment
        }
        const endpoint = `${this.lmsAPIUri}/api/v1/journey/${rootJourneyId}/${lessonId}/thumbs-down`;
        return this._apiService.post(endpoint, payload);
    }
}
