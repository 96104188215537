/*
 * Copyright (C) 2024 AIHR
 * License EULA
 *
 * This software and its contents are the property of [AIHR].
 * Unauthorized copying of this file, via any medium, is strictly prohibited.
 * Proprietary and confidential.
 */

export const loadingStoreKey = 'loading';
export const downloadingStoreKey = 'downloading';
export const rolesStoreKey = 'roles';
export const learningCatalogStoreKey = 'learningCatalog';
export const learningContentStoreKey = 'learningContent';