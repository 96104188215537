import { Component, DestroyRef, OnInit, TemplateRef } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Actions, ofType } from '@ngrx/effects';
import { CommonModule } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IButton } from '@interfaces';
import { showModal } from '@store/overlay-modal';

@Component({
    standalone: true,
    selector: 'app-overlay-modal',
    templateUrl: './overlay-modal.component.html',
    styleUrls: ['./overlay-modal.component.scss'],
    imports: [CommonModule, TranslateModule]
})
export class OverlayModalComponent implements OnInit {
    public imagePath: string;
    public title: string;
    public description: string;
    public closeButtonFunction: () => any;
    public primaryButtonInput: IButton;
    public secondaryButtonInput: IButton;

    public isModalActive: boolean;
    public isLoading: boolean;
    public additionalContentTemplateRef: TemplateRef<any>;

    private closeByOutsideClick: boolean;
    private closeByPrimaryButton: boolean;
    private closeBySecondaryButton: boolean;
    private onClosingFunction: () => any;
    private primaryButtonAction: () => any;
    private secondaryButtonAction: () => any;

    constructor(private _actions: Actions, private destroyRef: DestroyRef) {}

    public ngOnInit(): void {
        this._actions.pipe(ofType(showModal), takeUntilDestroyed(this.destroyRef)).subscribe(({modalInput}) => {
            if (modalInput) {
                this.isModalActive = true;
                this.isLoading = modalInput.isLoading;
                this.imagePath = modalInput.imagePath;
                this.title = modalInput.title;
                this.additionalContentTemplateRef = modalInput.additionalContentTemplate;
                this.description = modalInput.description;
                this.closeButtonFunction = modalInput.closeButtonFunction;
                this.primaryButtonInput = modalInput.primaryButtonInput;
                this.secondaryButtonInput = modalInput.secondaryButtonInput;
                this.primaryButtonAction = modalInput.primaryButtonAction;
                this.secondaryButtonAction = modalInput.secondaryButtonAction;
                this.closeByPrimaryButton = modalInput.closeByPrimaryButton;
                this.closeBySecondaryButton = modalInput.closeBySecondaryButton;
                this.closeByOutsideClick = modalInput.closeByOutsideClick;
                this.onClosingFunction = modalInput.onClosingFunction;
            }
        });
    }

    /**
     * function called when you click on the outside of modal
     */
    public outsideClick(): void {
        //if the modal is not loading then the user should be possible to close the modal
        if (!this.isLoading && this.closeByOutsideClick) {
            this.closeModal();
        }
    }

    /**
     * function called when you click the X close button
     */
    public closeButtonClick(): void {
        this.closeModal();
        if (this.closeButtonFunction) {
            this.closeButtonFunction();
        }
    }

    /**
     * function called when you click the primary button
     */
    public primaryButtonClick(): void {
        if (this.primaryButtonAction) {
            this.primaryButtonAction();
        }
        if (this.closeByPrimaryButton) {
            this.closeModal();
        }
    }

    /**
     * function called when you click the secondary button
     */
    public secondaryButtonClick(): void {
        if (this.secondaryButtonAction) {
            this.secondaryButtonAction();
        }
        if (this.closeBySecondaryButton) {
            this.closeModal();
        }
    }

    private closeModal(): void {
        this.isModalActive = false;

        if (this.onClosingFunction) {
            this.onClosingFunction();
        }
    }
}
