import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ILearningContentState } from "@learning-content/store";

export const learningContentState = createFeatureSelector<ILearningContentState>('learningContent');

export const nextLessonId = createSelector(learningContentState, state => state.nextLessonId);
export const previousLessonId = createSelector(learningContentState, state => state.previousLessonId);
export const rootJourneyLastLessonId = createSelector(learningContentState, state => state.rootJourneyLastLessonId);
export const currentLessonParentJourneyId = createSelector(learningContentState, state => state.currentLessonParentJourneyId);
export const journeyTitle = createSelector(learningContentState, state => state.journeyTitle);
export const journeyLearningStatus = createSelector(learningContentState, state => state.journeyLearningStatus);
export const isCurrentLessonLocked = createSelector(learningContentState, state => state.isCurrentLessonLocked);
export const currentInProgressLessonUrl = createSelector(learningContentState, state => state.currentInProgressLessonUrl);