/*
 * Copyright (C) 2024 AIHR
 * License EULA
 *
 * This software and its contents are the property of [AIHR].
 * Unauthorized copying of this file, via any medium, is strictly prohibited.
 * Proprietary and confidential.
 */

const GridBreakpointExtraSmall = '575.98px';
const GridBreakpointSmall = '576px';
const GridBreakpointMedium = '768px';
const GridBreakpointLarge = '992px';
const GridBreakpointExtraLarge = '1200px';
const GridBreakpointExtraExtraLarge = '1400px';
export const MaxWidthMediumScreen = '1199px';

const breakpoints: Record<string, string> = {
    xxl: `(min-width: ${GridBreakpointExtraExtraLarge})`,
    xl: `(min-width: ${GridBreakpointExtraLarge})`,
    lg: `(min-width: ${GridBreakpointLarge})`,
    md: `(min-width: ${GridBreakpointMedium})`,
    sm: `(min-width: ${GridBreakpointSmall})`,
    xs: `(max-width: ${GridBreakpointExtraSmall})`
};

export function getScreenSize(): string | null {
    for (const breakpoint in breakpoints) {
        if (window.matchMedia(breakpoints[breakpoint]).matches) {
            return breakpoint;
        }
    }
    // If no breakpoints match, return null or handle the default case
    return null;
}