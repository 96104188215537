/*
 * Copyright (C) 2024 AIHR
 * License EULA
 *
 * This software and its contents are the property of [AIHR].
 * Unauthorized copying of this file, via any medium, is strictly prohibited.
 * Proprietary and confidential.
 */

import { createReducer, on } from '@ngrx/store';
import {setRoles} from './roles.actions';

export interface IRolesState {
    roles: string[];
}

export const initialState: IRolesState = {
    roles: []
};


export const rolesReducer = createReducer(
    initialState,
    on(setRoles, (state, {roles}) => ({...state, roles})),
);

