<div class="notification-card-container" [class]="type">
    <div class="icon-container">
        <div class="icon" [class]="type" >i</div>
    </div>
    <div class="text-container">
        <div class="h2-standard title">
            {{ title || 'general.error.somethingWrong' | translate }}
        </div>
        <div class="n16-regular">
            {{ description || 'general.message.contactSupport'  | translate }}
        </div>
    </div>
    @if(shouldShowCloseButton) {
        <button class="close-icon" (click)="close()">
            <i class="far fa-xmark" aria-hidden="true" ></i>
        </button>
    }
</div>
