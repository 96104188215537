/*
 * Copyright (C) 2024 AIHR
 * License EULA
 *
 * This software and its contents are the property of [AIHR].
 * Unauthorized copying of this file, via any medium, is strictly prohibited.
 * Proprietary and confidential.
 */

export const StandaloneResourcesLibraryKey = 'UI_MENU_STANDALONE_RESOURCES_LIBRARY';
export const CopilotNamingKey = 'UI_MENU_COPILOT_NAMING'
