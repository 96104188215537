/*
 * Copyright (C) 2024 AIHR
 * License EULA
 *
 * This software and its contents are the property of [AIHR].
 * Unauthorized copying of this file, via any medium, is strictly prohibited.
 * Proprietary and confidential.
 */

import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpStatusCode, } from '@angular/common/http';
import { catchError, Observable, switchMap, throwError } from 'rxjs';
import { AuthenticationService, NotificationService } from '@services/core';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {

    constructor(private readonly _authService: AuthenticationService,
                private readonly _notificationService: NotificationService
    ) {
    }

    intercept<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
        const shouldSkipHeaders = request.headers?.get('skip') !== null;

        if (shouldSkipHeaders) {
            request = request.clone({ headers: request.headers.delete('skip') });
            return next.handle(request);
        }

        request = request.clone({
            headers: this.getDefaultHeaders(),
        });

        return next.handle(request).pipe(catchError(error => {
            if (error.status === HttpStatusCode.Unauthorized) {
                return this._authService.getSilentToken().pipe(switchMap(success => {
                    if (success) {
                        return next.handle(request.clone({ headers: this.getDefaultHeaders() }));
                    } else {
                        this._notificationService.error('You are not authenticated');
                        this._authService.logout();
                        return throwError(() => new HttpErrorResponse(error));
                    }
                }));
            }
            return throwError(() => new HttpErrorResponse(error));
        }));
    }

    private getDefaultHeaders(): HttpHeaders {
        return new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Authorization', `Bearer ${this._authService.getCachedAccessToken()}`);
    } 
}