/*
 * Copyright (C) 2024 AIHR
 * License EULA
 *
 * This software and its contents are the property of [AIHR].
 * Unauthorized copying of this file, via any medium, is strictly prohibited.
 * Proprietary and confidential.
 */

import {createAction, props} from '@ngrx/store';

export const setRoles = createAction( '[Roles] Set Roles', props<{ roles: string[] }>());

