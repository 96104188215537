import { createReducer, on } from "@ngrx/store";
import { 
    setCurrentInProgressLessonUrl,
    setCurrentLessonParentJourneyId,
    setIsCurrentLessonLocked,
    setJourneyLearningStatus,
    setJourneyTitle,
    setNextLessonId,
    setPreviousLessonId,
    setRootJourneyLastLessonId
} from "@learning-content/store";
import { LearningStatus } from "@enums";

export interface ILearningContentState {
    nextLessonId: number;
    previousLessonId: number;
    rootJourneyLastLessonId: number;
    currentLessonParentJourneyId: number;
    journeyTitle: string;
    journeyLearningStatus: LearningStatus;
    isCurrentLessonLocked: boolean;
    currentInProgressLessonUrl: string;
}

export const initState: ILearningContentState = {
    nextLessonId: null,
    previousLessonId: null,
    rootJourneyLastLessonId: null,
    currentLessonParentJourneyId: null,
    journeyTitle: null,
    journeyLearningStatus: null,
    isCurrentLessonLocked: null,
    currentInProgressLessonUrl: null
}

export const learningContentReducer = createReducer(
    initState,
    on(setNextLessonId, (state, action) => ({ ...state, nextLessonId: action.nextLessonId })),
    on(setPreviousLessonId, (state, action) => ({ ...state, previousLessonId: action.previousLessonId })),
    on(setRootJourneyLastLessonId, (state, action) => ({ ...state, rootJourneyLastLessonId: action.rootJourneyLastLessonId })),
    on(setCurrentLessonParentJourneyId, (state, action) => ({ ...state, currentLessonParentJourneyId: action.currentLessonParentJourneyId })),
    on(setJourneyTitle, (state, action) => ({ ...state, journeyTitle: action.journeyTitle })),
    on(setJourneyLearningStatus, (state, action) => ({ ...state, journeyLearningStatus: action.learningStatus })),
    on(setIsCurrentLessonLocked, (state, action) => ({ ...state, isCurrentLessonLocked: action.isCurrentLessonLocked })),
    on(setCurrentInProgressLessonUrl, (state, action) => ({ ...state, currentInProgressLessonUrl: action.currentInProgressLessonUrl }))
);