/*
 * Copyright (C) 2024 AIHR
 * License EULA
 *
 * This software and its contents are the property of [AIHR].
 * Unauthorized copying of this file, via any medium, is strictly prohibited.
 * Proprietary and confidential.
 */

import {createFeatureSelector, createSelector} from '@ngrx/store';
import {IDownloadingState} from './downloading.reducer';

export const loadingState = createFeatureSelector<IDownloadingState>('downloading');
export const downloadItems = createSelector(loadingState, state => state.downloadItems);
